import { lookAt } from './lookat';
import { perspective } from './perspective';

export const initParticles = async () => {
  if (typeof window === 'undefined') return;

  const hsv2rgb = (h: number, s: number, v: number, out?: number[]) => {
    const set = (r: number, g: number, b: number, out: number[]) => {
      out[0] = Math.round(r * 255);
      out[1] = Math.round(g * 255);
      out[2] = Math.round(b * 255);
    };

    const clamp = (v: number, l: number, u: number) => Math.max(l, Math.min(v, u));

    out = out || [0, 0, 0];
    h = h % 360;
    s = clamp(s, 0, 1);
    v = clamp(v, 0, 1);
    // Grey
    if (!s) {
      out[0] = out[1] = out[2] = Math.ceil(v * 255);
    } else {
      const b = (1 - s) * v;
      const vb = v - b;
      const hm = h % 60;
      switch ((h / 60) | 0) {
        case 0:
          set(v, (vb * h) / 60 + b, b, out);
          break;
        case 1:
          set((vb * (60 - hm)) / 60 + b, v, b, out);
          break;
        case 2:
          set(b, v, (vb * hm) / 60 + b, out);
          break;
        case 3:
          set(b, (vb * (60 - hm)) / 60 + b, v, out);
          break;
        case 4:
          set((vb * hm) / 60 + b, b, v, out);
          break;
        case 5:
          set(v, b, (vb * (60 - hm)) / 60 + b, out);
          break;
      }
    }
    return out;
  };

  const rf = await import('regl').then((m) => m.default);

  const NUM_POINTS = 1e2;
  const VERT_SIZE = 4 * (1 + 2 + 2);
  const p = document.getElementById('particles');

  const regl = rf({
    container: p || undefined,
  });

  const pointBuffer = regl.buffer(
    Array(NUM_POINTS)
      .fill(0)
      .map(() => {
        const color = hsv2rgb(Math.random() * 360, 0.6, 1);
        return [
          Math.random() * 10,
          Math.random() * 10,
          Math.random() * 10,
          Math.random() * 10,
          2.0 * Math.PI * Math.random(),
          2.0 * Math.PI * Math.random(),
          2.0 * Math.PI * Math.random(),
          2.0 * Math.PI * Math.random(),
          color[0] / 255,
          color[1] / 255,
          color[2] / 255,
        ];
      })
  );

  const drawParticles = regl({
    vert: `
      precision mediump float;
      attribute vec4 freq, phase;
      attribute vec3 color;
      uniform float time;
      uniform mat4 view, projection;
      varying vec3 fragColor;
      void main() {
        vec3 position = 8.5 * cos(freq.xyz * time + phase.xyz);
        gl_PointSize = 3.0 * (1.0 + cos(freq.w * time + phase.w));
        gl_Position = projection * view * vec4(position, 1);
        fragColor = color;
      }`,
    frag: `
      precision lowp float;
      varying vec3 fragColor;
      void main() {
        if (length(gl_PointCoord.xy - 0.5) > 0.5) {
          discard;
        }
        gl_FragColor = vec4(fragColor, 1);
      }`,
    attributes: {
      freq: { buffer: pointBuffer, stride: VERT_SIZE, offset: 44 },
      phase: { buffer: pointBuffer, stride: VERT_SIZE, offset: 4 },
      color: { buffer: pointBuffer, stride: VERT_SIZE, offset: 24 },
    },
    uniforms: {
      view: ({ tick }) =>
        lookAt(
          [],
          [30 * Math.cos(0.01 * tick), 2.5, 30 * Math.sin(0.01 * tick)],
          [0, 0, 0],
          [0, 1, 0]
        ),
      projection: ({ viewportWidth, viewportHeight }) =>
        perspective([], Math.PI / 4, viewportWidth / viewportHeight, 0.01, 1000),
      time: ({ tick }) => tick * 0.001,
    },
    count: NUM_POINTS,
    primitive: 'points',
  });

  regl.frame(drawParticles);
};
