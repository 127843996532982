import { useEffect, useRef } from 'react';
import { initParticles } from './particles';

const RenderParticals = () => {
  const isInitialized = useRef<boolean>();
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let observer: IntersectionObserver;

    if (containerRef.current && !isInitialized.current) {
      isInitialized.current = true;
      observer = new IntersectionObserver(
        async ([entry]) => {
          if (entry.isIntersecting) {
            await initParticles();
            observer.disconnect();
          }
        },
        {
          threshold: 0.1,
        }
      );

      observer.observe(containerRef.current);
    }

    return () => {
      // Cleanup observer if necessary
      if (observer) {
        observer.disconnect();
      }

      // Remove particles element
      const ele = document.getElementById('particles');
      if (ele) {
        ele.remove();
      }
    };
  }, [isInitialized]);

  return (
    <div
      id="particles"
      ref={containerRef}
      className="top-0 bottom-0 left-0 right-0 absolute opacity-20 dark:opacity-10 pointer-events-none"
    />
  );
};

export default RenderParticals;
